// 
let animations = require('./animations');
animations();

let menus = require('./menus');
menus();

let searchBar = require('./search_bar.js');
searchBar();

let slider = require('./slider');
slider();


// layouts
let base = require('./layouts/__base');
base();
let faqs = require('./layouts/faqs');
faqs();
let faq_tabs = require('./layouts/faq_tabs');
faq_tabs();
let service_tabs = require('./layouts/service_tabs');
service_tabs();
let locations = require('./layouts/locations');
locations();
let icon_cards = require('./layouts/icon_cards');
icon_cards();
let image_with_steps_narrow = require('./layouts/image_with_steps_narrow');
image_with_steps_narrow();
let taster_session = require('./layouts/taster_session');
taster_session();
let plans = require('./layouts/plans');
plans();

let header = require('./header.js');
header();



// template parts
let template_parts = require('./template-parts/video');
template_parts();