
/* --- SLIDER --- */
module.exports = () => {
	jQuery(document).ready(function () {

		jQuery('.slider').each(function() {
			jQuery(this).slick({
				dots: true,
				infinite: false,
				speed: 500,
				slidesToShow: 3,
				slidesToScroll: 3,
				arrows: false,
				centerPadding: '40px',
				autoplay: true,
				autoplaySpeed: 2000,
				mobileFirst: true,
				swipeToSlide: true,
				responsive: [
					{
						breakpoint: 900,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2
						}
					},
					{
						breakpoint: 540,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
					// You can unslick at a given breakpoint now by adding:
					// settings: "unslick"
					// instead of a settings object
				]
			})
		});
		jQuery(window).resize(function(){
			jQuery('.slider').slick('resize'); // recalc on window resize
		});


		jQuery('.taster-steps-slider').each(function() {
			jQuery(this).slick({
				dots: false,
				infinite: false,
				speed: 500,
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				nextArrow: jQuery(this).siblings('.arrow-container').children('.slick-custom-next'),
				prevArrow: jQuery(this).siblings('.arrow-container').children('.slick-custom-prev'),
				centerPadding: '40px',
				autoplay: false,
				autoplaySpeed: 2000,
				mobileFirst: true,
				swipeToSlide: false,
				swipeToScroll: false,
				draggable: false,
				swipe: false,
				responsive: [
					{
						breakpoint: 1100,
						settings: {
							dots: true
						}
					},
					// You can unslick at a given breakpoint now by adding:
					// settings: "unslick"
					// instead of a settings object
				]
			})
		});
		jQuery(window).resize(function(){
			jQuery('.taster-steps-slider').slick('resize'); // recalc on window resize
		});

		jQuery('.taster-steps-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			// let height = jQuery(this).children('.slick-list').children('.slick-track').children('.slick-active').height();
			if (event.target == this) {
				let nextEl = jQuery(this).children('.slick-list').children('.slick-track').children(`div[data-slick-index="${nextSlide}"]`);
				console.log(nextEl);
				let height = nextEl.height();
				this.style.height = (height + 60) + 'px';
			}

			setTimeout(function() {
				let smoothWrapper = document.getElementById('smooth-wrapper');
				let bodyHeight = smoothWrapper.offsetHeight;
				document.body.style.height = bodyHeight + "px";
			}, 600)
		})

		jQuery('.taster-products-slider, .taster-coaches-slider, .taster-location-slider').each(function() {
			jQuery(this).slick({
				dots: true,
				infinite: false,
				speed: 500,
				slidesToShow: 1.2,
				slidesToScroll: 1,
				arrows: false,
				centerPadding: '40px',
				autoplay: false,
				autoplaySpeed: 2000,
				mobileFirst: true,
				swipeToSlide: true,
				responsive: [
					{
						breakpoint: 675,
						settings: {
							slidesToShow: 2.2
						}
					},
					{
						breakpoint: 1100,
						settings: "unslick"
					}
					// You can unslick at a given breakpoint now by adding:
					// settings: "unslick"
					// instead of a settings object
				]
			})
		});
		jQuery(window).resize(function(){
			jQuery('.taster-steps-slider').slick('resize'); // recalc on window resize
		});


		jQuery('.cards_3_by_3 .cards').each(function() {
			jQuery(this).slick({
				dots: true,
				infinite: false,
				speed: 500,
				slidesToShow: 1.2,
				slidesToScroll: 1,
				arrows: true,
				centerPadding: '40px',
				autoplay: false,
				appendDots: jQuery(this).parent().find('.controls-container .slick-dots'),
				prevArrow: jQuery(this).parent().find('.controls-container .slick-prev'),
				nextArrow: jQuery(this).parent().find('.controls-container .slick-next'),
				autoplaySpeed: 2000,
				mobileFirst: true,
				swipeToSlide: true,
				responsive: [
					{
						breakpoint: 640,
						settings: {
							slidesToShow: 2.2,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 1100,
						settings: "unslick"
					}
					// You can unslick at a given breakpoint now by adding:
					// settings: "unslick"
					// instead of a settings object
				]
			});
		})
		jQuery(window).resize(function(){
			jQuery('.cards_3_by_3 .cards').slick('resize'); // recalc on window resize
		});


		jQuery('.cards_4_by_4 .cards').each(function() {
			// jQuery(this).slick({
			// 	dots: true,
			// 	infinite: false,
			// 	speed: 500,
			// 	slidesToShow: 1.1,
			// 	slidesToScroll: 1,
			// 	arrows: true,
			// 	centerPadding: '40px',
			// 	autoplay: false,
			// 	appendDots: jQuery(this).parent().find('.controls-container .slick-dots'),
			// 	prevArrow: jQuery(this).parent().find('.controls-container .slick-prev'),
			// 	nextArrow: jQuery(this).parent().find('.controls-container .slick-next'),
			// 	autoplaySpeed: 2000,
			// 	mobileFirst: true,
			// 	swipeToSlide: true,
			// 	responsive: [
			// 		{
			// 			breakpoint: 540,
			// 			settings: {
			// 				slidesToShow: 2.2,
			// 				slidesToScroll: 1
			// 			}
			// 		},
			// 		{
			// 			breakpoint: 1100,
			// 			settings: "unslick"
			// 		}
			// 		// You can unslick at a given breakpoint now by adding:
			// 		// settings: "unslick"
			// 		// instead of a settings object
			// 	]
			// });
		})
		jQuery(window).resize(function(){
			jQuery('.cards_4_by_4 .cards').slick('resize'); // recalc on window resize
		});




		jQuery('.cards_5_by_5 .cards').each(function() {
			// jQuery(this).slick({
			// 	dots: true,
			// 	infinite: false,
			// 	speed: 500,
			// 	slidesToShow: 1.1,
			// 	slidesToScroll: 1,
			// 	arrows: true,
			// 	centerPadding: '40px',
			// 	autoplay: false,
			// 	appendDots: jQuery(this).parent().find('.controls-container .slick-dots'),
			// 	prevArrow: jQuery(this).parent().find('.controls-container .slick-prev'),
			// 	nextArrow: jQuery(this).parent().find('.controls-container .slick-next'),
			// 	autoplaySpeed: 2000,
			// 	mobileFirst: true,
			// 	swipeToSlide: true,
			// 	responsive: [
			// 		{
			// 			breakpoint: 540,
			// 			settings: {
			// 				slidesToShow: 2.2,
			// 				slidesToScroll: 1
			// 			}
			// 		},
			// 		{
			// 			breakpoint: 800,
			// 			settings: {
			// 				slidesToShow: 3.2,
			// 				slidesToScroll: 1
			// 			}
			// 		},
			// 		{
			// 			breakpoint: 1200,
			// 			settings: "unslick"
			// 		}
			// 		// You can unslick at a given breakpoint now by adding:
			// 		// settings: "unslick"
			// 		// instead of a settings object
			// 	]
			// });
		});
		jQuery(window).resize(function(){
			jQuery('.cards_5_by_5 .cards').slick('resize'); // recalc on window resize
		});



		jQuery('.more_press .cards').each(function() {
			jQuery(this).slick({
				dots: true,
				infinite: false,
				speed: 500,
				slidesToShow: 1.1,
				slidesToScroll: 1,
				arrows: true,
				centerPadding: '40px',
				autoplay: false,
				appendDots: jQuery(this).parent().find('.controls-container .slick-dots'),
				prevArrow: jQuery(this).parent().find('.controls-container .slick-prev'),
				nextArrow: jQuery(this).parent().find('.controls-container .slick-next'),
				autoplaySpeed: 2000,
				mobileFirst: true,
				swipeToSlide: true,
				responsive: [
					{
						breakpoint: 540,
						settings: {
							slidesToShow: 2.2,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 800,
						settings: {
							slidesToShow: 3.2,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 1100,
						settings: "unslick"
					}
					// You can unslick at a given breakpoint now by adding:
					// settings: "unslick"
					// instead of a settings object
				]
			});
		})
		jQuery(window).resize(function(){
			jQuery('.more_press .cards').slick('resize'); // recalc on window resize
		});


		jQuery('.video_cards .cards').each(function() {
			jQuery(this).slick({
				dots: true,
				infinite: false,
				speed: 500,
				slidesToShow: 1.1,
				slidesToScroll: 1,
				arrows: true,
				centerPadding: '40px',
				autoplay: false,
				appendDots: jQuery(this).parent().find('.controls-container .slick-dots'),
				prevArrow: jQuery(this).parent().find('.controls-container .slick-prev'),
				nextArrow: jQuery(this).parent().find('.controls-container .slick-next'),
				autoplaySpeed: 2000,
				mobileFirst: true,
				swipeToSlide: true,
				responsive: [
					{
						breakpoint: 540,
						settings: {
							slidesToShow: 2.2,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 900,
						settings: {
							slidesToShow: 3.2,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 1279,
						settings: "unslick"
					}
					// You can unslick at a given breakpoint now by adding:
					// settings: "unslick"
					// instead of a settings object
				]
			});
		})
		jQuery(window).resize(function(){
			jQuery('.video_cards .cards').slick('resize'); // recalc on window resize
		});


		jQuery('.timeline .cards').each(function() {
			jQuery(this).slick({
				infinite: false,
				speed: 600,
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				centerPadding: '40px',
				autoplay: false,
				prevArrow: jQuery(this).parent().find('.controls-container .slick-prev'),
				nextArrow: jQuery(this).parent().find('.controls-container .slick-next'),
				autoplaySpeed: 2000,
				mobileFirst: true,
				swipeToSlide: true,
				responsive: [
					{
						breakpoint: 540,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 800,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 1100,
						settings: {
							slidesToShow: 4,
							slidesToScroll: 1
						}
					}
					// You can unslick at a given breakpoint now by adding:
					// settings: "unslick"
					// instead of a settings object
				]
			});
		})
		jQuery(window).resize(function(){
			jQuery('.timeline .cards').slick('resize'); // recalc on window resize
		});




		jQuery('.client_reviews .client-reviews-slider-desktop').slick({
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			centerPadding: '40px',
			autoplay: false,
			autoplaySpeed: 2000,
			mobileFirst: true,
			swipeToSlide: true,
		});
		jQuery(window).resize(function(){
			jQuery('.client_reviews .client-reviews-slider-desktop').slick('resize'); // recalc on window resize
		});

		jQuery('.client-reviews-slider-desktop .image').on('click', function() {
			let parent = jQuery(this).parent().parent().parent();
			parent.slick('slickGoTo', this.dataset.slickIndex);
		});
		jQuery('.client-reviews-slider-desktop').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			let matchingSlider = jQuery(this).nextAll('.client-reviews-slider-mobile');
			matchingSlider.slick('slickGoTo', nextSlide);
		});

		
		jQuery('.client_reviews .client-reviews-slider-mobile').each(function() {
			jQuery(this).slick({
				dots: true,
				infinite: false,
				speed: 500,
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				centerPadding: '40px',
				autoplay: false,
				appendDots: jQuery(this).parent().find('.slick-dots'),
				prevArrow: jQuery(this).parent().find('.slick-prev'),
				nextArrow: jQuery(this).parent().find('.slick-next'),
				autoplaySpeed: 2000,
				mobileFirst: true,
				swipeToSlide: true
			});
		})
		jQuery(window).resize(function(){
			jQuery('.client_reviews .client-reviews-slider-mobile').slick('resize'); // recalc on window resize
		});
		jQuery('.client-reviews-slider-mobile').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			let matchingSlider = jQuery(this).prevAll('.client-reviews-slider-desktop');
			matchingSlider.slick('slickGoTo', nextSlide);
		});



		jQuery('.pillars_cards .pillars').each(function() {
			jQuery(this).slick({
				dots: true,
				infinite: false,
				speed: 500,
				slidesToShow: 1.2,
				slidesToScroll: 1,
				arrows: false,
				centerPadding: '40px',
				autoplay: true,
				autoplaySpeed: 2000,
				mobileFirst: true,
				swipeToSlide: true,
				appendDots: jQuery(this).parent().find('.slick-dots'),
				prevArrow: jQuery(this).parent().find('.slick-prev'),
				nextArrow: jQuery(this).parent().find('.slick-next'),
				responsive: [
					{
						breakpoint: 540,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 900,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 2
						}
					},
					{
						breakpoint: 1280,
						settings: "unslick"
					}

					// You can unslick at a given breakpoint now by adding:
					// settings: "unslick"
					// instead of a settings object
				]
			});
		})
		jQuery(window).resize(function(){
			jQuery('.pillars_cards .pillars').slick('resize'); // recalc on window resize
		});



		jQuery('.faq_tabs .button-block').each(function() {
			jQuery(this).slick({
				dots: true,
				infinite: false,
				speed: 500,
				slidesToShow: 2.3,
				slidesToScroll: 1,
				arrows: true,
				appendDots: jQuery(this).parent().find('.slick-dots'),
				prevArrow: jQuery(this).parent().find('.slick-prev'),
				nextArrow: jQuery(this).parent().find('.slick-next'),
				variableWidth: true,
				autoplay: false,
				autoplaySpeed: 2000,
				mobileFirst: true,
				swipeToSlide: true,
				responsive: [
					{
						breakpoint: 0,
						settings: "unslick"
					},
					{
						breakpoint: 550,
						settings: {
							slidesToShow: 2.3,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 700,
						settings: {
							slidesToShow: 4.3,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 1230,
						settings: "unslick"
					}
					// You can unslick at a given breakpoint now by adding:
					// settings: "unslick"
					// instead of a settings object
				]
			});
		})
		jQuery(window).resize(function(){
			jQuery('.faq_tabs .button-block').slick('resize'); // recalc on window resize
		});

		jQuery('.reviews-small-slider').slick({
			dots: false,
			infinite: false,
			speed: 600,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			centerPadding: '0px',
			autoplay: true,
			autoplaySpeed: 3500,
			mobileFirst: true,
			swipeToSlide: true,
		});
		jQuery(window).resize(function(){
			jQuery('.reviews-small-slider').slick('resize'); // recalc on window resize
		});






		jQuery('.reviews-large-slider').each(function() {
			jQuery(this).slick({
				dots: true,
				infinite: true,
				speed: 500,
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				appendDots: jQuery(this).parent().find('.slick-dots'),
				prevArrow: jQuery(this).parent().find('.slick-prev'),
				nextArrow: jQuery(this).parent().find('.slick-next'),
				centerPadding: '40px',
				centerMode: true,
				autoplay: true,
				autoplaySpeed: 2500,
				mobileFirst: true,
				swipeToSlide: true,
				responsive: [
					{
						breakpoint: 600,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 900,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 1400,
						settings: {
							slidesToShow: 4,
							slidesToScroll: 1
						}
					}
				]
			});
		})
		jQuery(window).resize(function(){
			jQuery('.reviews-large-slider').slick('resize'); // recalc on window resize
		});



		jQuery('.categories-slider-flex:not(.single)').slick({
			dots: true,
			infinite: false,
			arrows: true,
			speed: 500,
			slidesToShow: 1.2,
			slidesToScroll: 1,
			centreMode: true,
			centerPadding: '40px',
			autoplay: false,
			autoplaySpeed: 2000,
			mobileFirst: true,
			swipeToSlide: true,
			responsive: [
				{
					breakpoint: 700,
					settings: {
						slidesToShow: 2.2
					}
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3.2
					}
				},
				{
					breakpoint: 1200,
					settings: "unslick"
				},

				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			]
		});
		jQuery(window).resize(function(){
			jQuery('.categories-slider-flex').slick('resize'); // recalc on window resize
		});



		jQuery('.clients-slider').slick({
			speed: 3000,
			autoplay: true,
			autoplaySpeed: 0,
			cssEase: 'linear',
			slidesToShow: 1,
			slidesToScroll: 1,
			variableWidth: true,
			arrows: false
		}); 
		jQuery(window).resize(function(){
			jQuery('.clients-slider').slick('resize'); // recalc on window resize
		});

		jQuery('.clients-white-bg-slider').slick({
			speed: 2000,
			autoplay: true,
			autoplaySpeed: 0,
			cssEase: 'linear',
			slidesToShow: 1,
			slidesToScroll: 1,
			variableWidth: true,
			arrows: false,
			centreMode: true
		}); 
		jQuery(window).resize(function(){
			jQuery('.clients-white-bg-slider').slick('resize'); // recalc on window resize
		});


		jQuery('.featured_in_slider').slick({
			speed: 2000,
			autoplay: true,
			autoplaySpeed: 0,
			cssEase: 'linear',
			slidesToShow: 1,
			slidesToScroll: 1,
			variableWidth: true,
			mobileFirst: true,
			arrows: false,
			centreMode: true,
			responsive: [
				{
					breakpoint: 1280,
					settings: "unslick"
				}
			]
		}); 
		jQuery(window).resize(function(){
			jQuery('.featured_in_slider').slick('resize'); // recalc on window resize
		});



		jQuery('.hero-slider').slick({
			speed: 5000,
			autoplay: true,
			autoplaySpeed: 0,
			cssEase: 'linear',
			slidesToShow: 1,
			slidesToScroll: 1,
			variableWidth: true,
			arrows: false
		}); 
		jQuery(window).resize(function(){
			jQuery('.hero-slider').slick('resize'); // recalc on window resize
		});

		jQuery('.hero-slider-2').slick({
			speed: 6000,
			autoplay: true,
			autoplaySpeed: 0,
			cssEase: 'linear',
			slidesToShow: 1,
			slidesToScroll: 1,
			variableWidth: true,
			arrows: false,
			rtl: true
		});
		jQuery(window).resize(function(){
			jQuery('.hero-slider-2').slick('resize'); // recalc on window resize
		});




		jQuery('.plans-slider').slick({
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			initialSlide: 1,
			arrows: false,
			centerPadding: '30px',
			centerMode: true,
			autoplay: false,
			autoplaySpeed: 2000,
			mobileFirst: true,
			swipeToSlide: true,
			responsive: [
				{
					breakpoint: 500,
					settings: {
						centerPadding: "50px"
					}
				},
				{
					breakpoint: 650,
					settings: {
						centerPadding: "150px"
					}
				},
				{
					breakpoint: 900,
					settings: "unslick"
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			]
		});
		jQuery(window).resize(function(){
			jQuery('.plans-slider').slick('resize'); // recalc on window resize
		});





		jQuery('.option-cards-flex').each(function() {
			jQuery(this).slick({
				dots: true,
				infinite: false,
				speed: 500,
				slidesToShow: 1,
				slidesToScroll: 1,
				initialSlide: 0,
				arrows: true,
				appendDots: jQuery(this).parent().find('.slick-dots'),
				prevArrow: jQuery(this).parent().find('.slick-prev'),
				nextArrow: jQuery(this).parent().find('.slick-next'),
				centerPadding: '30px',
				centerMode: true,
				autoplay: false,
				autoplaySpeed: 2000,
				mobileFirst: true,
				swipeToSlide: true,
				responsive: [
					{
						breakpoint: 500,
						settings: {
							centerPadding: "50px"
						}
					},
					{
						breakpoint: 650,
						settings: {
							centerPadding: "150px"
						}
					},
					{
						breakpoint: 900,
						settings: "unslick"
					}
					// You can unslick at a given breakpoint now by adding:
					// settings: "unslick"
					// instead of a settings object
				]
			});
		})
		jQuery(window).resize(function(){
			jQuery('.option-cards-flex').slick('resize'); // recalc on window resize
		});



		jQuery('.coaches-headshot-slider').each(function() {
			let initialSlide = Math.round(jQuery(this).children().length / 2) - 1;
			jQuery(this).slick({
				dots: true,
				infinite: false,
				speed: 650,
				slidesToShow: 1,
				slidesToScroll: 1,
				initialSlide: initialSlide,
				arrows: false,
				centerPadding: '0px',
				centerMode: true,
				autoplay: false,
				autoplaySpeed: 2000,
				mobileFirst: true,
				swipeToSlide: true,
				responsive: [
					{
						breakpoint: 800,
						settings: {
							dots: false
						}
					}
				]
			});
		})
		jQuery(window).resize(function(){
			jQuery('.coaches-headshot-slider').slick('resize'); // recalc on window resize
		});
		jQuery('.coaches-headshot-slider .headshot').on('click', function() {
			let parent = jQuery(this).parent().parent().parent();
			parent.slick('slickGoTo', this.dataset.slickIndex);
		});

		jQuery('.coaches-headshot-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
				let matchingSlider = jQuery(this).nextAll('.coaches-slider');
				matchingSlider.slick('slickGoTo', nextSlide);
				console.log('headshot trigger');
		});



		jQuery('.coaches-slider').each(function() {
			let initialSlide = Math.round(jQuery(this).children().length / 2) - 1;
			jQuery(this).slick({
				dots: false,
				infinite: false,
				speed: 800,
				slidesToShow: 1,
				slidesToScroll: 1,
				initialSlide: initialSlide,
				arrows: true,
				centerPadding: '0px',
				centerMode: true,
				autoplay: false,
				autoplaySpeed: 2000,
				mobileFirst: true,
				swipeToSlide: true,
				responsive: [
					{
						breakpoint: 800,
						settings: {
							dots: true
						}
					}
				]
			});
		});
		jQuery(window).resize(function(){
			jQuery('.coaches-slider').slick('resize'); // recalc on window resize
		});

		jQuery('.coaches-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			let matchingSlider = jQuery(this).prevAll('.coaches-headshot-slider');
			matchingSlider.slick('slickGoTo', nextSlide);
		});



		jQuery('.team-headshot-slider').each(function() {
			let initialSlide = Math.round(jQuery(this).children().length / 2) - 1;
			jQuery(this).slick({
				dots: true,
				infinite: false,
				speed: 800,
				slidesToShow: 1,
				slidesToScroll: 1,
				initialSlide: initialSlide,
				arrows: false,
				centerPadding: '0px',
				centerMode: true,
				centerPadding: '30px',
				autoplay: false,
				autoplaySpeed: 2000,
				mobileFirst: true,
				swipeToSlide: true,
				variableWidth: true,
				responsive: [
					{
						breakpoint: 800,
						settings: {
							dots: false
						}
					}
				]
			});
		});

		jQuery(window).resize(function(){
			jQuery('.team-headshot-slider').slick('resize'); // recalc on window resize
		});
		jQuery('.team-headshot-slider .headshot').on('click', function() {
			let parent = jQuery(this).parent().parent().parent();
			parent.slick('slickGoTo', this.dataset.slickIndex);
		});
		jQuery('.team-headshot-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			let matchingSlider = jQuery(this).nextAll('.team-slider');
			matchingSlider.slick('slickGoTo', nextSlide);
		});



		jQuery('.team-slider').each(function() {
			let initialSlide = Math.round(jQuery(this).children().length / 2) - 1;
			jQuery(this).slick({
				dots: false,
				infinite: false,
				speed: 800,
				slidesToShow: 1,
				slidesToScroll: 1,
				initialSlide: initialSlide,
				arrows: true,
				centerPadding: '0px',
				centerMode: true,
				autoplay: false,
				autoplaySpeed: 2000,
				mobileFirst: true,
				swipeToSlide: true,
				responsive: [
					{
						breakpoint: 800,
						settings: {
							dots: true
						}
					}
				]
			});
		});

		jQuery(window).resize(function(){
			jQuery('.team-slider').slick('resize'); // recalc on window resize
		});

		jQuery('.team-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			let matchingSlider = jQuery(this).prevAll('.team-headshot-slider');
			matchingSlider.slick('slickGoTo', nextSlide);
		});






		jQuery('.locations-slider').each(function() {
			jQuery(this).slick({
				dots: true,
				appendDots: jQuery('.locations .slick-dots'),
				infinite: false,
				speed: 500,
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				centerPadding: '40px',
				centerMode: true,
				autoplay: false,
				autoplaySpeed: 2000,
				mobileFirst: true,
				swipeToSlide: true,
				prevArrow: jQuery(this).parent().find('.slick-prev'),
				nextArrow: jQuery(this).parent().find('.slick-next'),
				responsive: [
					{
						breakpoint: 1200,
						settings: "unslick"
					},
					{
						breakpoint: 900,
						settings: {
							slidesToShow: 3,
							centerMode: false,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 540,
						settings: {
							slidesToShow: 2,
							centerMode: false,
							slidesToScroll: 1
						}
					}
					// You can unslick at a given breakpoint now by adding:
					// settings: "unslick"
					// instead of a settings object
				]
			});
		})
		jQuery(window).resize(function(){
			jQuery('.locations-slider').slick('resize'); // recalc on window resize
		});


	});
}