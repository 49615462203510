module.exports = () => {

    document.addEventListener('DOMContentLoaded', function() {
        let submitButtons = document.querySelectorAll('.plans form button');
        
        for (let i=0; i<submitButtons.length; i++) {
            let button = submitButtons[i];

            button.addEventListener('click', function() {
                let link = this.parentNode.action;
                let target = this.parentNode.target;

                if (target == "_blank") {
                    Object.assign(document.createElement('a'), {
                        target: '_blank',
                        rel: 'noopener noreferrer',
                        href: link,
                    }).click();
                }
                else if (link) {
                    window.location.href = link;
                }
            })
        }
    })

}