
module.exports = () => {

    document.addEventListener("DOMContentLoaded", function() {
        let buttons = document.querySelectorAll('.faq_tabs .button-block button');

        for (let i=0; i<buttons.length; i++) {
            let button = buttons[i];
            button.addEventListener('click', function() {
                let id = this.dataset.id;

                // show new faq block
                let oldFaq = document.querySelector('.faqs.open');
                let newFaq = document.querySelector(`#faqs-${id}`);
                oldFaq.classList.remove('open');
                newFaq.classList.add('open');

                // colour new button
                let oldBtn = document.querySelector('.faq_tabs .button-block .open');
                oldBtn.classList.remove('open');
                this.classList.add('open');
                
                
            })
        }
      });
    
}