
module.exports = () => {

    document.addEventListener("DOMContentLoaded", function() {
        let arrows = document.querySelectorAll('.image_with_steps_narrow .arrow');

        console.log(arrows);

        for (let i=0; i<arrows.length; i++) {
            let arrow = arrows[i];

            arrow.addEventListener('click', function() {
                let parent = this.parentNode;
                if (parent.classList.contains('open')) {
                    parent.classList.remove('open');
                } else {
                    parent.classList.add('open');
                }
            })

        }

      });
    
}