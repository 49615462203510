
module.exports = () => {

    jQuery(window).load(function() {


        // opening of window
        let playButtons = document.querySelectorAll('.play-button');

        for (let i = 0; i < playButtons.length; i++) {

            let button = playButtons[i];

            button.addEventListener('click', function() {
                let id = this.id;
                let video = document.querySelector(`.video-container[data-videoid="${id}"]`);
                video.classList.add('visible');

                // add enable js api
                let iframe = video.querySelector('iframe');
                if (!iframe.src.includes('&enablejsapi=1')) {
                    iframe.src += '&enablejsapi=1';
                }
                // document.body.style.top = `-${window.scrollY}px`;
                // document.body.style.position = 'fixed';
                
            })

        }


        // closing of window
        let videoContainers = document.querySelectorAll('.video-container');
        for (let i = 0; i < videoContainers.length; i++) {
            let videoContainer = videoContainers[i];

            videoContainer.addEventListener('click', function(e) {
                if (e.target !== this) {
                    return;
                }

                let iframe = this.querySelector('iframe');
                iframe.contentWindow.postMessage( '{"event":"command", "func":"stopVideo", "args":""}', '*');
                this.classList.remove('visible');

                // When the modal is hidden, we want to remain at the top of the scroll position
                // const scrollY = document.body.style.top;
                // document.body.style.position = '';
                // document.body.style.top = '';
                // window.scrollTo(0, parseInt(scrollY || '0') * -1);
            })
        }




    })
    
}