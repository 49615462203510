
module.exports = () => {
    document.addEventListener('DOMContentLoaded', function() {

        // auto move to next step on selection of steps
        let slider = jQuery('.taster-steps-slider');
        let buttons = slider.find('input[type="radio"]:not(.form input)');

        for (let i = 0; i < buttons.length; i++) {
            let button = buttons[i];

            button.addEventListener('click', function() {

                let currentSlide = slider.children('.slick-list').children('.slick-track').children('.slick-active')[0].dataset.slickIndex;
                let nextSlide = Number(currentSlide) + 1;
                
                window.setTimeout(() => slider.slick('slickGoTo', nextSlide), 500);
            })
        }

    })
}