
module.exports = () => {

    jQuery(document).ready(function() {
    
        let arrows = document.querySelectorAll('.locations .dropdown-arrow');
        for (let i = 0; i < arrows.length; i++) {
            let arrow = arrows[i];

            arrow.addEventListener('click', function() {
                let parent = this.parentNode.parentNode.parentNode;
                let description = parent.querySelector('.description > .inner');
                let wysiwyg = description.querySelector('.wysiwyg');
                let height = wysiwyg.offsetHeight + 15;


                if (parent.classList.contains('open')) {
                    parent.classList.remove('open');
                    description.style.height = "0px";
                } else {
                    parent.classList.add('open');
                    description.style.height = height + "px";
                }
            })
        }

    })
    
}