
module.exports = () => {

    document.addEventListener("DOMContentLoaded", function() {
        let arrows = document.querySelectorAll('.icon_cards .arrow');

        for (let i=0; i<arrows.length; i++) {
            let arrow = arrows[i];

            arrow.addEventListener('click', function() {
                let parent = this.parentNode;
                if (parent.classList.contains('open')) {
                    parent.classList.remove('open');
                } else {
                    parent.classList.add('open');
                }
            })
        }

        let titles = document.querySelectorAll('.icon_cards .card > h4');
        
        for (let i=0; i<titles.length; i++) {
            let title = titles[i];

            title.addEventListener('click', function() {
                let parent = this.parentNode;
                if (parent.classList.contains('open')) {
                    parent.classList.remove('open');
                } else {
                    parent.classList.add('open');
                }
            })
        }

      });
    
}