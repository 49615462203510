module.exports = () => {



    document.addEventListener('DOMContentLoaded', function() {

        let calculateContentContainerMarginTop = function() {
            // get height of header
            let header = document.querySelector('header');
            let headerHeight = header.offsetHeight - 1;
            
            // add height of header as margin top to content container
            let contentContainer = document.querySelector('.content-container');
            contentContainer.style.marginTop = headerHeight + 'px';
        }

        calculateContentContainerMarginTop();


        window.addEventListener('resize', calculateContentContainerMarginTop);

    })

}