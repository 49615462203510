
module.exports = () => {
    jQuery(document).ready(function() {

        // expand categories container on load
        let categoriesContainer = document.getElementsByClassName('categories')[0];
        let firstCategory = document.getElementsByClassName('categories-slider-container')[0];
        let height = firstCategory.offsetHeight + 50;
        categoriesContainer.style.height = height + "px";


        let buttons = document.getElementsByClassName('category-button');
        for (let i = 0; i<buttons.length; i++) {
            let button = buttons[i];

            button.addEventListener('click', function() {

                // make all buttons inactive, and make clicked button active
                for (let j=0; j<buttons.length; j++) {
                    if (!buttons[j].classList.contains('inactive')) {
                        buttons[j].classList.add('inactive');
                    }
                }
                if (this.classList.contains('inactive')) {
                    this.classList.remove('inactive');
                }

                // find target category to fade in
                let id = button.dataset.id;
                let target = document.querySelector(`#cat-${id}`);

                // hide current
                let current = document.querySelector('.categories-slider-container.active');
                current.classList.remove('active');

                // show target
                target.classList.add('active');
                console.log(target);

                // recalculate parent height
                categoriesContainer.style.height = target.offsetHeight + "px";

                setTimeout(function() {
                    let smoothWrapper = document.getElementById('smooth-wrapper');
                    let bodyHeight = smoothWrapper.offsetHeight;
                    document.body.style.height = bodyHeight + "px";
                }, 600)
            })
        }

    })
}